.footer-nav {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  padding: 1em 0;
  background-color: black;
  z-index: 10;
}

.footer-nav ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.footer-nav li {
  margin: 0 1em;
}

.footer-nav a {
  color: white;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-family: DrukaatieBurtiBold, sans-serif;
}

.footer-nav a:hover,
.footer-nav a.active {
  text-decoration: underline;
  color: #f158c3;
}

.footer-nav .footer-nav-icon:last-of-type {
  display: none;
}

.footer-nav a.active .footer-nav-icon:first-of-type {
  display: none;
}

.footer-nav a.active .footer-nav-icon:last-of-type {
  display: inline-flex;
}

.footer-nav-label {
  margin: 0.5rem 0 0 0;
}

.footer-nav-icon {
  width: 64px;
  height: 64px;
}
