.App {
  text-align: center;
  background-size: cover;
  color: white;
  min-height: 100vh;
  font-size: 1.25rem;
}

.names {
  font-family: "SilverStoneRegular", sans-serif;
  font-size: 3rem;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 1rem;
}

.separator {
  margin: 0 0.75rem;
  height: 7.5rem;
  display: inline-flex;
  align-self: center;
}

.photo {
  max-width: 100%;
}

@media (min-width: 500px) {
  .App {
    font-size: 1.5rem;
  }

  .photo {
    max-width: 20rem;
  }

  .separator {
    margin: 0 2rem;
  }
}
