body {
  margin: 0;
  font-family: DrukaatieBurtiBold, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  background-color: black;
  padding-bottom: 100px;
}

@font-face {
  font-family: "SilverStoneRegular";
  font-style: normal;
  font-weight: normal;
  src: local("SilverStoneRegular"),
    url("./fonts/SilverStone/SilverStone-Regular.woff2") format("woff");
}

@font-face {
  font-family: "DrukaatieBurtiBold";
  font-style: normal;
  font-weight: normal;
  src: local("DrukaatieBurtiBold"),
    url("./fonts/DrukaatieBurti/DrukaatieBurti-Bold.woff") format("woff");
}

@font-face {
  font-family: "Alex Brush";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/AlexBrush/Alex-Brush.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: "EuphoriaScript";
  font-style: normal;
  font-weight: normal;
  src: local("EuphoriaScript"),
    url("./fonts/EuphoriaScript/EuphoriaScript-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Bakerie";
  font-style: normal;
  font-weight: normal;
  src: local("Bakerie"),
    url("./fonts/Bakerie/BakerieSmooth-Regular.otf") format("opentype");
}

.container {
  padding: 1rem;
}

.justify-content-center {
  justify-content: center;
}

img {
  max-width: 100%;
}

blockquote {
  font-family: "EuphoriaScript", sans-serif;
  font-size: 1.5rem;
  margin-top: 4rem;
  text-align: center;
}

.title {
  font-family: "SilverStoneRegular", sans-serif;
  font-size: 3rem;
  font-weight: 400;
  margin-top: 0;
}

.secondary-page {
  padding: 1rem;
}

@media (min-width: 500px) {
  blockquote {
    font-size: 1.75rem;
  }

  .secondary-page {
    padding: 2rem;
  }
}
