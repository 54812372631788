.layout {
  background-image: url(../images/background.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top center;
  padding: 12vw 6vw 3rem 6vw;
  max-width: 1000px;
  margin: 0 auto;
}

@media (min-width: 1001px) {
  .layout {
    padding: 120px 60px 3rem 60px;
  }
}
